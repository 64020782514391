import {
  createTable,
  getCoreRowModel,
  getPaginationRowModel,
  useTableInstance,
} from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { FiCalendar } from 'react-icons/fi';
import { GiClick } from 'react-icons/gi';

import { Indicator } from '../../components';
import { Calendar } from '../../components/Calendar';
import { MainContainer } from '../../components/MainContainer';
import { Button } from '../../components/Button';
import { ReportClientInfoPay } from '../../components/Modals/Reports/ClientPayInfo';
import { RecyclingUpdateWeight } from '../../components/Modals/Reports/updateWeight';
import { ReportShow } from '../../components/Modals/Reports/Show';

import { getReports } from '../../services/Order';
import { ReportProps } from '../../services/Order/types';

import { format, getStatusOrder, dateToString, formatDate } from '../../util';

import {
  ContainerCalendar,
  ContainerFilter,
  IndicatorContainer,
  Table,
  TableButton,
  TableFooter,
  Loading,
  TextWeight,
} from './styles';

interface PeriodProps {
  dateStart: string;
  dateEnd: string | null;
}

const table = createTable().setRowType<ReportProps>();

export function Reports() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userId = query.get('hashcod_user');

  const [reports, setReports] = useState<ReportProps[]>([]);

  const [selectedReport, setSelectedReport] = useState<ReportProps>(
    {} as ReportProps
  );

  const [client, setClient] = useState<string | null>('');
  const [userHashcod, setuserHashcod] = useState<string | null>(userId);

  const [collaborator, setCollaborator] = useState<string | null>('');
  const [residue, setResidue] = useState<string | null>('');
  const [status, setStatus] = useState<string | null>('');
  const [period, setPeriod] = useState<PeriodProps | null>({
    dateStart: new Date(Date.now()).toString(),
    dateEnd: null,
  });

  const [totalWasteWeight, setTotalWasteWeight] = useState<string>('');
  const [totalAmountPayable, setTotalAmountPayable] = useState<string>('');

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalPaymentInfoIsOpen, setModalPaymentInfoIsOpen] =
    useState<boolean>(false);

  const [modalUpdateWeightIsOpen, setModalUpdateWeightIsOpen] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
  const [numberPages, setNumberPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const COLUMNS = [
    table.createDataColumn(report => report.user_hashcod.name, {
      id: 'user_hashcod',
      header: 'CLIENTE',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('date_cad', {
      header: 'DATA DA SOLICITAÇÃO',
      cell: info => formatDate(info.getValue()),
    }),
    table.createDataColumn('recycling_day_time', {
      header: 'DATA E HORA DA RETIRADA',
      cell: info => formatDate(info.getValue()),
    }),
    table.createDataColumn(report => report.collaborator_hashcod.name, {
      id: 'collaborator_hashcod',
      header: 'COLABORADOR',
      cell: info => info.getValue(),
    }),
    table.createDataColumn(report => report.waste_types_hashcod.name, {
      id: 'waste_types_hashcod',
      header: 'RESÍDUO',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('waste_weight', {
      id: 'report_weight',
      header: 'PESO',
      cell: props => (
        <TableButton onClick={() => showUpdateWeightModal(props.row.original)}>
          <TextWeight>{props.row.original.waste_weight}</TextWeight>
        </TableButton>
      ),
    }),
    table.createDataColumn('status_order', {
      header: 'STATUS',
      cell: info => getStatusOrder(info.getValue()),
    }),
    table.createDataColumn('amount_payable', {
      header: 'VALOR',
      cell: info => format('CURRENCY', info.getValue()),
    }),
    table.createDisplayColumn({
      id: 'is_paid',
      header: 'PAGAR',
      cell: props =>
        !props.row.original.is_paid && (
          <TableButton onClick={() => showPaymentInfoModal(props.row.original)}>
            <GiClick size={22} color='var(--fern)' />
          </TableButton>
        ),
    }),
    table.createDisplayColumn({
      id: 'report_details',
      header: 'VISUALIZAR',
      cell: props => (
        <TableButton onClick={() => showReportDetailsModal(props.row.original)}>
          <AiFillEye size={22} color='var(--fern)' />
        </TableButton>
      ),
    }),
  ];

  const tableInstance = useTableInstance(table, {
    columns: COLUMNS,
    data: reports,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
  });

  const [state, setState] = useState(tableInstance.initialState);

  tableInstance.setOptions(previousState => ({
    ...previousState,
    state,
    onStateChange: setState,
  }));

  function showReportDetailsModal(order: ReportProps): void {
    setSelectedReport(order);

    setModalIsOpen(!modalIsOpen);
  }

  function showPaymentInfoModal(order: ReportProps): void {
    setSelectedReport(order);

    setModalPaymentInfoIsOpen(!modalPaymentInfoIsOpen);
  }

  function showUpdateWeightModal(order: ReportProps): void {
    setSelectedReport(order);

    setModalUpdateWeightIsOpen(!modalUpdateWeightIsOpen);
  }

  function showCalendar(): void {
    setIsCalendarOpen(!isCalendarOpen);
  }

  const fetchReports = useCallback(async () => {
    setIsLoading(true);

    const response = await getReports({
      pagina: currentPage,
      number_items_page: 15,
      name_user: client,
      name_collaborator: collaborator,
      name_waste_type: residue,
      status_order: status || null,
      date_start: period.dateStart,
      date_end: period.dateEnd,
      user_hashcod: userHashcod,
    });

    if (response.search.length) {
      setReports(response.search);
      setNumberPages(response.number_pages);
      setTotalWasteWeight(format('WEIGHT', response.total_waste_weight));
      setTotalAmountPayable(format('CURRENCY', response.total_amount_payable));
      setHasNextPage(!!response.next_page);
      setHasPreviousPage(!!response.previus_page);
      setIsLoading(false);
      return;
    }

    setReports([]);
    setNumberPages(1);
    setCurrentPage(1);
    setHasNextPage(false);
    setHasPreviousPage(false);
    setIsLoading(false);
  }, [
    userHashcod,
    client,
    collaborator,
    period.dateEnd,
    period.dateStart,
    residue,
    status,
    currentPage,
  ]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  return (
    <MainContainer title='Relatórios'>
      <IndicatorContainer>
        <Indicator
          title='Peso Total'
          cardTitle='Peso'
          cardSubtitle={totalWasteWeight}
        />

        <Indicator
          title='Valor Total'
          cardTitle='Valor'
          cardSubtitle={totalAmountPayable}
        />
      </IndicatorContainer>

      <>
        <ContainerFilter>
          <label>
            Clientes
            <input
              value={client}
              onChange={event => {
                setCurrentPage(1);
                setClient(event.target.value);
              }}
            />
          </label>

          <label>
            Colaborador
            <input
              value={collaborator}
              onChange={event => {
                setCurrentPage(1);
                setCollaborator(event.target.value);
              }}
            />
          </label>

          <label>
            Resíduo
            <input
              value={residue}
              onChange={event => {
                setCurrentPage(1);
                setResidue(event.target.value);
              }}
            />
          </label>

          <label>
            Status
            <select
              onChange={event => {
                setCurrentPage(1);
                setStatus(event.target.value);
              }}
            >
              <option value=''>Todos</option>
              <option value='PENDING'>Pendente</option>
              <option value='ACCEPTED'>Aceito</option>
              <option value='REFUSED'>Recusado</option>
              <option value='CANCELED'>Cancelado</option>
            </select>
          </label>

          <ContainerCalendar>
            <label>
              Período
              <button onClick={showCalendar}>
                <FiCalendar size={18} />
              </button>
            </label>
          </ContainerCalendar>
          <div style={{ position: 'relative' }}>
            <Calendar
              isCalendarOpen={isCalendarOpen}
              onClose={showCalendar}
              onClean={() => {
                setCurrentPage(1);
                setPeriod({
                  dateStart: null,
                  dateEnd: null,
                });
                showCalendar();
              }}
              onConfirm={date => {
                setCurrentPage(1);
                setPeriod({
                  dateStart: dateToString(date[0]),
                  dateEnd: dateToString(date[1]),
                });
                showCalendar();
              }}
            />
          </div>
        </ContainerFilter>

        <Table>
          <thead>
            {tableInstance.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : header.renderHeader()}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {isLoading ? (
              <tr className='uniqueCellContainer'>
                <td className='uniqueCell'>
                  <Loading />
                </td>
              </tr>
            ) : (
              <>
                {reports.length ? (
                  <>
                    {tableInstance.getRowModel().rows.map(row => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>{cell.renderCell()}</td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className='uniqueCellContainer'>
                    <td className='uniqueCell'>
                      <strong>Você não tem reciclagens para mostrar</strong>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>

        <TableFooter>
          <div>
            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(1)}
              disabled={!hasPreviousPage}
              marginRight={10}
            >
              {'<<'}
            </Button>

            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={!hasPreviousPage}
            >
              {'<'}
            </Button>

            <span>
              {currentPage}/{numberPages}
            </span>

            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={!hasNextPage}
            >
              {'>'}
            </Button>

            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(numberPages)}
              disabled={!hasNextPage}
              marginLeft={10}
            >
              {'>>'}
            </Button>
          </div>
        </TableFooter>

        <ReportShow
          handleCloseModal={() => setModalIsOpen(!modalIsOpen)}
          modalIsOpen={modalIsOpen}
          client={selectedReport}
        />

        <ReportClientInfoPay
          handleCloseModal={() =>
            setModalPaymentInfoIsOpen(!modalPaymentInfoIsOpen)
          }
          modalIsOpen={modalPaymentInfoIsOpen}
          clientPaymentInfo={selectedReport}
        />

        <RecyclingUpdateWeight
          handleCloseModal={() =>
            setModalUpdateWeightIsOpen(!modalUpdateWeightIsOpen)
          }
          modalIsOpen={modalUpdateWeightIsOpen}
          recyclingInfo={selectedReport}
          updateList={fetchReports}
        />
      </>
    </MainContainer>
  );
}
