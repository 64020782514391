import React, { useCallback } from 'react';
import { ModalBase, ModalBaseProps } from '../';
import { deleteArea } from '../../../services/Area';
import { ListAreaResponseProps } from '../../../services/Area/types';
import { Button } from '../../Button';
import { useToast } from '../../../context/ToastContext';
import { errorMessage } from '../../../util/messages';

interface AreaDeleteProps extends Omit<ModalBaseProps, 'title'> {
  area: ListAreaResponseProps;
  hasDeleted: (hascode: string) => void;
}

export const AreaDelete: React.FC<AreaDeleteProps> = ({
  modalIsOpen,
  handleCloseModal,
  hasDeleted,
  area,
}) => {
  const { addToast } = useToast();

  const handleDeleteArea = useCallback(async () => {
    try {
      const { hashcod } = area;
      await deleteArea(hashcod);
      addToast({ type: 'success', title: 'Sucesso' });
      hasDeleted(hashcod);
      handleCloseModal();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um Error',
        description: errorMessage.INVALID_CREDENTIALS,
      });
    }
  }, [addToast, handleCloseModal, hasDeleted, area]);

  return (
    <ModalBase
      modalIsOpen={modalIsOpen}
      handleCloseModal={handleCloseModal}
      title='DESEJA CONTINUAR?'
    >
      <p>
        Deseja Excluir o item <strong>{area.title}</strong>?
      </p>

      <Button onClick={handleDeleteArea}>Confirmar</Button>
    </ModalBase>
  );
};
