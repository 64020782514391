import React, { useCallback, useRef, useState } from 'react';
import Modal from 'react-modal';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { getFormError } from '../../../util/formErrors';
import * as Yup from 'yup';

import { FiX } from 'react-icons/fi';
import { Input } from '../../Input';
import { Button } from '../../Button';
import { useToast } from '../../../context/ToastContext';

import { registerArea, editingArea } from '../../../services/Area';
import { ListAreaResponseProps } from '../../../services/Area/types';

import { Container, Header } from './styles';
import { errorMessage } from '../../../util/messages';

interface AreaRegistryProps {
  modalIsOpen: boolean;
  handleCloseModal: () => void;
  area?: ListAreaResponseProps;
  isSuccessful: () => void;
}

interface AreaData {
  hashcod?: string;
  title: string;
  area: string;
  center: string;
}

export const AreaRegistry: React.FC<AreaRegistryProps> = ({
  modalIsOpen,
  handleCloseModal,
  isSuccessful,
  area,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: AreaData, { reset }) => {
      setLoading(true);
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string().required(),
        });
        await schema.validate(data, { abortEarly: false });
        !data.hashcod
          ? await registerArea(data)
          : //@ts-ignore
            await editingArea(data?.hashcod, data);

        addToast({
          type: 'success',
          title: 'Sucesso',
        });
        reset();
        handleCloseModal();
        isSuccessful();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getFormError(error);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um Error',
            description:
              error.status === 404
                ? errorMessage.NOT_FOUND
                : errorMessage.DEFAULT,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleCloseModal, isSuccessful]
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      contentLabel='Example Modal'
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Container>
        <Header>
          <h2>CADASTRO DE ÁREA</h2>
          <button onClick={handleCloseModal}>
            <FiX size={30} />
          </button>
        </Header>

        <Form
          ref={formRef}
          initialData={{
            ...area,
          }}
          onSubmit={handleSubmit}
        >
          <Input hidden name='hashcod' />
          <Input name='title' placeholder='Título da área:' />
          <Input name='area' placeholder='Área' />
          <Input name='center' placeholder='Área da logo' />
          <Button loading={loading} type='submit'>
            {area?.hashcod ? 'Editar' : 'Cadastrar'}
          </Button>
        </Form>
      </Container>
    </Modal>
  );
};
