// import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';
import ReactCalendar from 'react-calendar';

import { Container, ContainerButton } from './styles';
import '../../styles/calendar.css';

interface CalendarProps {
  onConfirm: (date: Date | Date[]) => void;
  onClose: () => void;
  onClean: () => void;
  isCalendarOpen: boolean;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export function Calendar({
  onConfirm,
  onClose,
  onClean,
  isCalendarOpen,
}: CalendarProps) {
  const [selectedDate, setSelectedDate] = useState<Value>(new Date());

  return (
    <>
      {isCalendarOpen && (
        <Container>
          <ReactCalendar
            onChange={setSelectedDate}
            value={selectedDate}
            locale='pt'
            prev2Label=''
            next2Label=''
            selectRange
            view='month'
          />

          <ContainerButton>
            <button onClick={onClean}>LIMPAR</button>
            <button onClick={onClose}>CANCELAR</button>
            <button onClick={() => onConfirm(selectedDate)}>OK</button>
          </ContainerButton>
        </Container>
      )}
    </>
  );
}
