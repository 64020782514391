import { api } from '..';

import { GetReportsParamsProps, GetReportsResponseProps } from './types';

export async function listUsers(
  params: GetReportsParamsProps
): Promise<GetReportsResponseProps | null> {
  try {
    const { data } = await api.get<GetReportsResponseProps>('user/adm/list/', {
      params,
    });
    if (data) return data;
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}
