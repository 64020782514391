import { api } from '..';

import { GetReportsParamsProps, GetReportsResponseProps } from './types';

export async function getReports(
  params: GetReportsParamsProps
): Promise<GetReportsResponseProps | null> {
  try {
    const { data } = await api.get<GetReportsResponseProps>(
      'recycling/order/',
      {
        params,
      }
    );

    if (data) return data;

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function payOrder(order_hashcod: any): Promise<any> {
  let uri = `recycling/order/pay/${order_hashcod}/
  `;
  return new Promise((resolve, reject) => {
    api
      .put(uri, {})
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response.data));
  });
}

export function updateWeightOrder(order: any, waste_weight: any): Promise<any> {
  let uri = `recycling/order/collaborator/weight/update/${order?.hashcod}/
  `;
  return new Promise((resolve, reject) => {
    api
      .put(uri, { waste_weight: waste_weight })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response.data));
  });
}
