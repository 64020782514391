import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 50;

  display: flex;
  flex-direction: column;

  top: 30px;
  right: 0;

  width: 350px;

  background: #fff;

  border: 1px solid #a0a096;
`;

export const ContainerButton = styled.div`
  display: flex;

  justify-content: space-between;

  margin: 10px 0;

  button {
    padding: 15px;

    color: #7c4dff;

    border: none;

    &:hover {
      color: #7c4dff81;
    }
  }
`;
