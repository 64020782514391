import { VscLoading } from 'react-icons/vsc';
import styled from 'styled-components';

export const IndicatorContainer = styled.div`
  display: flex;

  justify-content: flex-end;
  align-items: center;

  gap: 1.625rem;

  padding-right: 2rem;
`;

export const Table = styled.table`
  box-shadow: 2px 2px 1px (--prussian-blue);
  border-collapse: collapse;

  thead {
    th {
      height: 50px;

      color: var(--white);

      font-size: 15px;
      font-weight: normal;

      background: var(--blue-stone);

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  tbody {
    .uniqueCellContainer {
      position: relative;

      .uniqueCell {
        position: absolute;

        width: 100%;

        margin-top: 10px;
      }
    }

    tr {
      height: 50px;

      font-size: 17px;

      text-align: center;

      td {
        border-right: 1px solid #ccc;

        &:last-child {
          border: none;
        }
      }

      &:nth-child(even) td {
        background: rgba(0, 84, 104, 0.1);
      }
    }
  }
`;

export const TableButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
`;

export const ContainerFilter = styled.div`
  margin-bottom: 10px;
  display: flex;
  label {
    position: relative;
    margin-right: 10px;
    input,
    select {
      margin-left: 5px;
      border: none;
      border-bottom: 1px solid;
    }
  }
`;

export const ContainerCalendar = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border: none;
    margin-left: 5px;
  }
`;

export const TableFooter = styled.footer`
  display: flex;

  width: 100%;

  justify-content: flex-end;
  align-items: center;

  margin-top: 15px;

  div {
    display: flex;

    width: 25%;

    justify-content: center;
    align-items: center;

    span {
      margin: 0 15px;
    }
  }
`;

export const Loading = styled(VscLoading)`
  font-size: 24px;

  animation: spin infinite 0.5s linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const TextWeight = styled.text`
  font-size: 17px;
  color: #000;
  text-decoration-line: underline;
`;
