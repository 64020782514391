import React from 'react';
import Images from '../../config/theme/images';
import {
  Container,
  SideMenuContainerLogo,
  SideMenuContainerNav,
  SideMenuNav,
} from './styles';

interface SideMenuProps {
  opened: boolean;
}

export const SideMenu: React.FC<SideMenuProps> = ({ opened }) => {
  return (
    <Container isOpened={opened}>
      <SideMenuContainerLogo>
        <img
          src={Images.logo}
          alt='Logo'
          style={{ height: 80, marginTop: 15 }}
        />
      </SideMenuContainerLogo>
      <SideMenuContainerNav>
        <SideMenuNav to='/indicators'>Indicadores</SideMenuNav>
        <SideMenuNav to='/reports'>Relatórios de Reciclagens</SideMenuNav>
        <SideMenuNav to='/users'>Relatórios de Usuários</SideMenuNav>
        <SideMenuNav to='/wastes'>Cadastro de Resíduos</SideMenuNav>
        <SideMenuNav to='/areas'>Cadastro de Áreas</SideMenuNav>
        <SideMenuNav to='/general_settings'>Configurações Gerais</SideMenuNav>
      </SideMenuContainerNav>
    </Container>
  );
};
