import React, { useCallback, useEffect, useState } from 'react';
import { HiXCircle } from 'react-icons/hi';
import { RiPencilFill } from 'react-icons/ri';

import { MainContainer } from '../../components/MainContainer';
import { AreaDelete } from '../../components/Modals/AreaDelete';
import { AreaRegistry } from '../../components/Modals/AreaRegistry';

import { useToast } from '../../context/ToastContext';

import { listArea } from '../../services/Area';
import { ListAreaResponseProps } from '../../services/Area/types';

import { Table, TableButton } from './styles';

export const Areas: React.FC = () => {
  const [showModalAreaRegistry, setShowModalAreaRegistry] = useState(false);
  const [showModalAreaDelete, setShowModalAreaDelete] = useState(false);
  const [areas, setAreas] = useState<ListAreaResponseProps[]>([]);
  const [areaSelected, setAreaSelected] = useState<ListAreaResponseProps>(
    {} as ListAreaResponseProps
  );
  const [loadingAreas, setLoadingAreas] = useState(true);
  const { addToast } = useToast();

  useEffect(() => {
    handleListArea();
  }, []);

  const handleListArea = async () => {
    try {
      const response = await listArea();

      setAreas(() => [...response]);
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Ocorreu um error',
        description: error?.data?.detail,
      });
    } finally {
      setLoadingAreas(false);
    }
  };

  const handleCloseModalAreaRegistry = useCallback(() => {
    setShowModalAreaRegistry(false);
    setAreaSelected({} as ListAreaResponseProps);
  }, []);

  const handleOpenModalAreaRegistry = useCallback(
    (area?: ListAreaResponseProps) => {
      setShowModalAreaRegistry(true);
      if (area) setAreaSelected(area);
    },
    []
  );

  const handleCloseModalAreaDelete = useCallback(() => {
    setShowModalAreaDelete(false);
    setAreaSelected({} as ListAreaResponseProps);
  }, []);

  const handleOpenModalAreaDelete = useCallback(
    (area: ListAreaResponseProps) => {
      setShowModalAreaDelete(true);
      setAreaSelected(area);
    },
    []
  );

  const handleDeletedItem = useCallback((hashcod: string) => {
    setAreas(oldAreas => oldAreas.filter(areas => areas.hashcod !== hashcod));
  }, []);

  return (
    <MainContainer
      title='Cadastro de Áreas'
      button={{
        title: 'Cadastrar Área',
        onClick: () => handleOpenModalAreaRegistry(),
      }}
    >
      <Table>
        <thead>
          <tr>
            <th>TÍTULO</th>
            <th>EDITAR</th>
            <th>EXCLUIR</th>
          </tr>
        </thead>
        {loadingAreas ? (
          <></>
        ) : (
          <tbody>
            {areas.map(area => (
              <tr key={area.hashcod}>
                <td>{area.title}</td>
                <td>
                  <TableButton
                    onClick={() => handleOpenModalAreaRegistry(area)}
                  >
                    <RiPencilFill size={22} color='var(--fern)' />
                  </TableButton>
                </td>
                <td>
                  <TableButton onClick={() => handleOpenModalAreaDelete(area)}>
                    <HiXCircle size={22} color='var(--red)' />
                  </TableButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>

      <AreaRegistry
        modalIsOpen={showModalAreaRegistry}
        handleCloseModal={handleCloseModalAreaRegistry}
        area={areaSelected}
        isSuccessful={() => handleListArea()}
      />
      <AreaDelete
        area={areaSelected}
        modalIsOpen={showModalAreaDelete}
        handleCloseModal={handleCloseModalAreaDelete}
        hasDeleted={hashcod => handleDeletedItem(hashcod)}
      />
    </MainContainer>
  );
};
