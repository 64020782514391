import {
  createTable,
  getCoreRowModel,
  getPaginationRowModel,
  useTableInstance,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { MainContainer } from '../../components/MainContainer';
import { Button } from '../../components/Button';
import { listUsers } from '../../services/User';
import { User } from '../../services/User/types';
import { formatDate } from '../../util';

import { Table, TableFooter, Loading } from './styles';

const table = createTable().setRowType<User>();

export function Users() {
  const [reports, setReports] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
  const [numberPages, setNumberPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const COLUMNS = [
    table.createDataColumn('date_cad', {
      header: 'DATA CAD.',
      cell: info => formatDate(info.getValue()),
    }),
    table.createDataColumn('name', {
      header: 'NOME',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('email', {
      header: 'E-MAIL',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('phone', {
      header: 'TELEFONE',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('cpf_cnpj', {
      header: 'CPF / CNPJ',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('city', {
      header: 'Cidade',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('state', {
      header: 'Estado',
      cell: info => info.getValue(),
    }),
    table.createDataColumn('recycling_total', {
      header: 'Reciclagens',
      cell: props => (
        <Link to={`/reports?hashcod_user=${props.row.original.hashcod}`}>
          {props.row.original.recycling_total}
        </Link>
      ),
    }),
  ];

  const tableInstance = useTableInstance(table, {
    columns: COLUMNS,
    data: reports,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
  });

  const [state, setState] = useState(tableInstance.initialState);

  tableInstance.setOptions(previousState => ({
    ...previousState,
    state,
    onStateChange: setState,
  }));

  const fetchReports = useCallback(async () => {
    setIsLoading(true);

    const response = await listUsers({
      pagina: currentPage,
      number_items_page: 15,
    });

    if (response.search.length) {
      setReports(response.search);
      setNumberPages(response.number_pages);
      setHasNextPage(!!response.next_page);
      setHasPreviousPage(!!response.previus_page);
      setIsLoading(false);
      return;
    }

    setReports([]);
    setNumberPages(1);
    setCurrentPage(1);
    setHasNextPage(false);
    setHasPreviousPage(false);
    setIsLoading(false);
  }, [currentPage]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  return (
    <MainContainer title='Relatório de usuários'>
      <>
        <Table>
          <thead>
            {tableInstance.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : header.renderHeader()}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {isLoading ? (
              <tr className='uniqueCellContainer'>
                <td className='uniqueCell'>
                  <Loading />
                </td>
              </tr>
            ) : (
              <>
                {reports.length ? (
                  <>
                    {tableInstance.getRowModel().rows.map(row => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>{cell.renderCell()}</td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className='uniqueCellContainer'>
                    <td className='uniqueCell'>
                      <strong>Nenhum usuário cadastrado</strong>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>

        <TableFooter>
          <div>
            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(1)}
              disabled={!hasPreviousPage}
              marginRight={10}
            >
              {'<<'}
            </Button>

            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={!hasPreviousPage}
            >
              {'<'}
            </Button>

            <span>
              {currentPage}/{numberPages}
            </span>

            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={!hasNextPage}
            >
              {'>'}
            </Button>

            <Button
              type='button'
              width={'65px'}
              height={41}
              onClick={() => setCurrentPage(numberPages)}
              disabled={!hasNextPage}
              marginLeft={10}
            >
              {'>>'}
            </Button>
          </div>
        </TableFooter>
      </>
    </MainContainer>
  );
}
