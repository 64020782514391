import { api } from '../';
import {
  RegisterAreaRequest,
  RegisterAreaResponse,
  ListAreaResponseProps,
  EditingAreaRequest,
  EditingAreaResponse,
  DeleteAreaResponse,
} from './types';

export const registerArea = (
  data: RegisterAreaRequest
): Promise<RegisterAreaResponse> => {
  return new Promise((resolve, reject) => {
    api
      .post('settings/areas/', data)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};

export const listArea = (): Promise<ListAreaResponseProps[]> => {
  return new Promise((resolve, reject) => {
    api
      .get('settings/areas/')
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};

export const editingArea = (
  hashcod: string,
  body: EditingAreaRequest
): Promise<EditingAreaResponse> => {
  return new Promise((resolve, reject) => {
    api
      .put(`settings/areas/${hashcod}/`, { ...body })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};

export const deleteArea = (hashcod: string): Promise<DeleteAreaResponse> => {
  return new Promise((resolve, reject) => {
    api
      .delete(`settings/areas/${hashcod}/`)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};
