export const STAGE = {
  development: {
    apiUrl: 'http://127.0.0.1:8000/api/v1',
  },
  homologation: {
    apiUrl: 'https://api.homologacao.recyclenoapp.com.br/api/v1',
  },
  production: {
    apiUrl: 'https://api.recyclenoapp.com.br/api/v1',
  },
};

export const API_URL = STAGE.production.apiUrl;
