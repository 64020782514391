import { Form } from '@unform/web';
import React, { useCallback, useState } from 'react';
import { ModalBase, ModalBaseProps } from '../../';
import { useToast } from '../../../../context/ToastContext';
import { updateWeightOrder } from '../../../../services/Order';
import { Button } from '../../../Button';
import { Input } from '../../../Input';
import { Container } from './styles';

interface ReportShowProps extends Omit<ModalBaseProps, 'title'> {
  recyclingInfo: any;
  modalIsOpen: boolean;
  updateList: () => void;
  handleCloseModal: () => void;
}

export const RecyclingUpdateWeight: React.FC<ReportShowProps> = ({
  modalIsOpen,
  updateList,
  handleCloseModal,
  recyclingInfo,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [wasteWeight, setWasteWeight] = useState<string>(
    recyclingInfo?.waste_weight
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const response = await updateWeightOrder(recyclingInfo, wasteWeight);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Alteração realizada com sucesso.',
      });
      updateList();
      handleCloseModal();
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao realizar a alteração do peso.',
      });
      return error;
    }
  }, [addToast, handleCloseModal, wasteWeight, updateList]);

  return (
    <ModalBase
      modalIsOpen={modalIsOpen}
      handleCloseModal={handleCloseModal}
      title='EDITAR QUANTIDADE'
    >
      <Container>
        <Form onSubmit={handleSubmit} initialData={recyclingInfo}>
          <Input hidden name='hashcod' />
          <Input
            placeholder='Quantidade'
            name='waste_weight'
            value={wasteWeight}
            onChange={e => setWasteWeight(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Button
              style={{
                width: 300,
              }}
              loading={loading}
              type='submit'
            >
              {'Editar'}
            </Button>
          </div>
        </Form>
      </Container>
    </ModalBase>
  );
};
